<template>
  <FocusTrap>
    <div id="mycard">
      <div class="row">
        <div class="col"><h3>Doctors Reporting</h3></div>
        <div class="col">
          <span> <i class="fa fa-fw fa-sort"></i></span>
        </div>
      </div>

      <div class="row">

        <div class="col-md-3" v-for="report in reportings" :key="`${report.doctor.id}`">
          <DoctorVisit :key="`${report.doctor.id}`" :id="`doctor_${report.doctor.id}`" :reporting="report"/>
        </div>

      </div>

    </div>


  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import DoctorVisit from '../../../components/DoctorVisit'

export default {
  name: 'DoctorReporting',
  store,
  components: { DoctorVisit },
  data () {
    return {
      working_date: moment(),
      date_title: '',
      cards: [],
      reportings: [],
      today_areas: [],
    }
  },
  created () {
    this.$data.working_date = this.$store.state.user.working_date;
  },
  mounted () {
    const self = this;

    self.loadDayPlan();
  },
  methods: {
    loadDayPlan () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      try {

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.today_areas = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/dayplan/emp/${self.$store.state.user.id}/${self.$data.working_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#mycard').unblock()

          if (resp.ok) {

            if (!_.isNull(resp.data)) {

              if(Array.isArray(resp.data.areas)) {
                resp.data.areas.forEach((a) => {
                  self.$data.today_areas.push(a.area_id)
                });
              }

              resp.data.doctors.forEach((myday) => {

                  let rep = {
                    'id': 0,
                    'work_date': '',
                    "fieldpeople_id":0,
                    "jointworker_id":0,
                    "doctor_id":0,
                    'doctor': {
                      'id': 0,
                      'name': '',
                      'hospital': '',
                      'sex': 'M',
                      'address': '',
                      'area_id': 1,
                      'qualification': 'qualification',
                      'speciality': '',
                      'class': '',
                      'dob': '1900-01-01',
                      'dow': '',
                      'no_of_visits': 0,
                      'last_visit': '2000-01-01',
                      'mobile': '',
                      'email': '',
                      'web': '',
                      'whatsapp': '',
                      'spouse_name': '',
                      'spouse_dob': '',
                      'spouse_profession': '',
                      'data': '',
                      'area': { 'id': 1, 'name': '' },
                      'categories': []
                    },
                    'visit_time': '',
                    'visited': false,
                    'not_met_type': '',
                    'not_met_remarks': '',
                    'lbl_reminder': false,
                    'sample_reminder': false,
                    'cmc_reminder': false,
                    'products': []
                  };
                  rep.doctor = myday.doctor;
                  self.$store.state.user.products.forEach((product) =>{
                    if(product.id > 1) {
                      let item = {"id":product.id, "name":product.name};
                      rep.products.push( item);
                    }
                  });
                  self.$data.reportings.push(rep);
              });
              // self.loadReportingDoctors();
            }

          } else {
            swal({ title: 'Oops!', text: 'Day Planning not found', type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        }).finally(function () {
          $('#mycard').unblock()
        })
      } catch (e) {
        alert(e)
      }

    },
    loadReportingDoctors () {
      const self = this

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      let areas = JSON.stringify(self.$data.today_areas);

      areas = areas.replace('[', '');
      areas = areas.replace(']', '');

      let url = `${process.env.VUE_APP_ROOT_API}api/doctors/active/area/${areas}`;

      if( areas.length == 0 ) {
        url = `${process.env.VUE_APP_ROOT_API}api/doctors/active/area/0`;
      }
      self.$data.reportings = [];
      fetch(url, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            resp.data.forEach((doctor) => {
              let rep = {
                'id': 0,
                'work_date': '',
                "fieldpeople_id":0,
                "jointworker_id":0,
                "doctor_id":0,
                'doctor': {
                  'id': 0,
                  'name': '',
                  'hospital': '',
                  'sex': 'M',
                  'address': '',
                  'area_id': 1,
                  'qualification': 'qualification',
                  'speciality': '',
                  'class': '',
                  'dob': '1900-01-01',
                  'dow': '',
                  'no_of_visits': 0,
                  'last_visit': '2000-01-01',
                  'mobile': '',
                  'email': '',
                  'web': '',
                  'whatsapp': '',
                  'spouse_name': '',
                  'spouse_dob': '',
                  'spouse_profession': '',
                  'data': '',
                  'area': { 'id': 1, 'name': '' },
                  'categories': []
                },
                'visit_time': '',
                'visited': false,
                'not_met_type': '',
                'not_met_remarks': '',
                'lbl_reminder': false,
                'sample_reminder': false,
                'cmc_reminder': false,
                'products': []
              };
              rep.doctor = doctor;
              self.$store.state.user.products.forEach((product) =>{
                if(product.id > 1) {
                  rep.products.push( JSON.parse(JSON.stringify(product)));
                }
              });

              self.$data.reportings.push(rep);
            })
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      }).finally(function () {
        $('#mycard').unblock()
      })
    },
    perform_save_action () {
      const self = this;

      const requestOptions = {
        method:  (self.$data.report.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.report)
      };

      self.$data.report.visited = false;

      fetch(`${process.env.VUE_APP_ROOT_API}api/reporting`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer: 3000} );
          self.$emit('report_saved', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });

    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
