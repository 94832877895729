<script>
import {userService} from '@/store/auth-header.js'
import {store} from '@/store/store.js'
import ReportingProductInfo from '../views/mms/reporting/ReportingProductInfo'
import XPButton from './core/XPButton'

export default {
  name: 'DoctorVisitYes',
  components: { XPButton, ReportingProductInfo },
  store,
  props:{
    reporting : {
      type :Object,
      default:() => JSON.parse('{"id":0,"work_date":"","doctor":{"id":0,"name":"Doctor Name","hospital":"","sex":"M","address":"","area_id":1,"qualification":"qualification","speciality":"","class":"","dob":"1900-01-01","dow":"","no_of_visits":0,"last_visit":"2000-01-01","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":1,"name":""},"categories":[]},"visit_time":"","visited":false,"not_met_type":"","not_met_remarks":"","lbl_reminder":false,"sample_reminder":false,"cmc_reminder":false,"products":[],"promotions":[],"cme":{"title":"","comment":""},"gift":{"title":"","emoji":"","comment":""},"discussion":{"title":"","commitment":""},"pending_cme":{"id":"","title":"","comment":""}}')
    },
    products :{
      type : Array,
      default:() => []
    },
    selected_product:{"id":0,"name":""},
  },
  data(){
    return {
      promotions : [],
      cme:{"title":"","comment":""},
      gift:{"title":"","emoji":"","comment":""},
      discussion:{"title":"","commitment":""},
      pending_cme:{"id":"","title":"","comment":""},
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
    console.log(JSON.stringify(this.$props.reporting));
    // this.reporting =  JSON.parse(JSON.stringify(this.reporting));
  },
  mounted () {
    const self = this;

    // alert(this.$props.reporting.doctor.name);
  },
  methods:{
    item_qty_changed(){
    },
    cme_update_clicked(){
      const self = this;
      try {
        self.$emit('cme_updated', self.$data.cme);
        // alert(self.$data.cme.title);
        // alert('cme_update_clicked');
        self.$modal.hide('cme_window');
      }catch (e) {
        alert(e);
      }
    },
    pending_cme_update_clicked(){
      const self = this;

      self.$emit('pending_cme_updated', self.$data.pending_cme);
      this.$modal.hide('pending_cme_window');
      // alert('pending_cme_update_clicked');
    },
    gift_update_clicked(){
      const self = this;
      self.$emit('gift_updated', self.$data.gift);
      self.$modal.hide('gift_window');
      // alert('gift_update_clicked');
    },
    discussion_update_clicked(){
      const self = this;
      self.$emit('discussion_updated', self.$data.discussion);
      // alert('discussion_update_clicked');
      self.$modal.hide('discussion_window');
    },
    yes_update_clicked(){
      const self = this;
      try{
        // self.get_selected_products();
        self.$props.reporting.doctor_id = self.$props.reporting.doctor.id;
        self.$props.reporting.fieldpeople_id = self.$store.state.user.id;
        // self.$data.reporting.jointworker_id = resp.data.jointworker_id;
        self.$props.reporting.work_date =  self.$store.state.user.working_date;
        self.$props.reporting.visited =  true;
        self.$props.reporting.visit_time =  moment().format('YYYY-MM-DD HH:mm:ss');
        self.$emit('doctor_visit_yes_updated', self.$props.reporting);
      }catch (e) {
        alert(e);
      }
    },
    get_selected_products(){
      const self = this;
      try {

        let checkboxes = document.getElementsByName('cbProduct');
        let result = "";
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            result += checkboxes[i].value + ", ";
          }
        }

        // self.$props.products.forEach((product) =>{
        //   self.$props.reporting.products.push(product);
        // });
      }catch (e) {
        alert(e);
      }
    },
    report_breakup(item){
      this.selected_product = JSON.parse(item);
      this.$modal.show('breakup_window');
    },
    product_promotion_saved(promotion){
      const self = this;
      try {
        self.$modal.hide('breakup_window');
        // self.$data.promotions.push(promotion);
        self.$data.promotions.forEach((promo,idx)=>{
          if(promo.product.id == promotion.product.id) {
            delete self.$data.promotions[idx];
          }
        });

        //Remove the Nulls from Array
        self.$data.promotions = self.$data.promotions.reduce((acc, item) => {
          if (item !== null) {
            acc.push(item);
          }
          return acc;
        }, []);
        self.$data.promotions.push(promotion);

        self.$props.reporting.promotions = self.$data.promotions;

        // console.log(JSON.stringify(self.$data.promotions));
      }catch (e) {
        alert(e);
      }
    },
    show_discussion_window(){
      this.$modal.show('discussion_window');
    },
    show_gift_window(){
      this.$modal.show('gift_window');
    },
    show_cme_window(){
      this.$modal.show('cme_window');
    },
    show_pending_cme_window(){
      this.$modal.show('pending_cme_window');
    },
  }
}
</script>

<template>
  <FocusTrap>
    <div class="card">
    <div class="card-header p-1 pt-2 pl-2 text-danger-800" >
<!--      <h4 class="font-weight-semibold">{{reporting.doctor.name}} Visit Updates</h4>-->
      <div class="card-header header-elements-inline">
        <h6 class="card-title">{{reporting.doctor.name}}</h6>
        <div class="header-elements">
          <button class="btn btn-outline-success ml-1" @click="show_cme_window" >CME</button>
          <button class="btn btn-outline-secondary ml-1" @click="show_gift_window">Inputs</button>
          <button class="btn btn-outline-teal ml-1" @click="show_discussion_window">Discussion</button>
          <button class="btn btn-outline-danger ml-1" @click="show_pending_cme_window">Pending CME</button>
          <div class="list-icons">
<!--            <a class="list-icons-item" data-action="collapse"></a>-->
<!--            <a class="list-icons-item" data-action="reload"></a>-->
            <a class="list-icons-item" data-action="remove"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive table-scrollable">
        <table class="table" >
          <thead>
<!--          <th style="width: 40px;">#</th>-->
          <th>Product</th>
<!--          <th style="width: 50px;">Qty</th>-->
          <th style="width: 50px;">#</th>
          </thead>
          <tbody style="height: 750px">

          <tr class="odd"  v-for="(product,idx) in reporting.products" :key="idx" style="padding: 0px;">
            <td>
              <h6 class="mb-0">
                <a href="#" class="text-primary-800" @click="report_breakup(`${ JSON.stringify(product)}`)">{{product.name}}</a>
                <span class="d-block font-size-sm text-muted">{{product.name}}</span>
              </h6>
            </td>

            <td>
              <span class="badge-mark text-success-600"><i class="icon-checkmark4 text-success-600"/></span>
            </td>

          </tr>

<!--          <tr v-for="(product,idx) in reporting.products" :key="idx" style="padding: 0px;">-->
<!--            <td style="padding: 0px;">{{product.name}}</td>-->
<!--            <td style="padding: 0px;"> <button class="btn btn-outline" @click="report_breakup(`${ JSON.stringify(product)}`)">Info</button> </td>-->
<!--          </tr>-->
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer text-right" style="padding: 10px;" >
      <button class="btn btn-outline-success" @click="yes_update_clicked">Update</button>
    </div>

      <!--Product Promotion-->
      <FocusTrap>
        <vmodal name="breakup_window" transition="nice-modal-fade" :delay="100" :resizable="true"  height="520" >
          <ReportingProductInfo :product="selected_product" @reporting_product_info_saved="product_promotion_saved" ></ReportingProductInfo>
        </vmodal>
      </FocusTrap>

      <!--Discussion Window-->
      <FocusTrap>
        <vmodal name="discussion_window" transition="nice-modal-fade" :delay="100" :resizable="true"  height="440" >
          <div class="card">
            <div class="card-title p-0" style="background-color: whitesmoke;">
              <h6 class="card-header">{{reporting.doctor.name}} Discussions</h6>
            </div>
            <div class="card-body">
              <div class="form-group row">
                <label>Discussion:</label>
                <textarea class="form-control" rows="3" v-model="discussion.title" />
              </div>
              <div class="form-group row">
                <label>Commitment:</label>
                <textarea class="form-control" rows="3" v-model="discussion.commitment" />
              </div>
            </div>
            <div class="card-footer">
              <button class="btn btn-outline-success" @click="discussion_update_clicked" >Update</button>
            </div>
          </div>
        </vmodal>
      </FocusTrap>

      <!--Gift Window-->
      <FocusTrap>
        <vmodal name="gift_window" transition="nice-modal-fade" :delay="100" :resizable="true"  height="480" >
          <div class="card">
            <div class="card-title p-0" style="background-color: whitesmoke;">
              <h6 class="card-header">{{reporting.doctor.name}} Inputs</h6>
            </div>
            <div class="card-body">

              <div class="form-group row">
                <label>Input:</label>
                <input class="form-control" maxlength="50" v-model="gift.title"  />
              </div>

              <div class="form-group row">
                <label>Emoji:</label>
                <select class="form-control" v-model="gift.emoji">
                  <option value="👍">👍</option>
                  <option value="👎">👎</option>
                  <option value="😀">😀</option>
                  <option value="😣">😣</option>
                  <option value="🥱">🥱</option>
                  <option value="🥱">🥱</option>
                </select>
              </div>

              <div class="form-group row">
                <label>Final Words:</label>
                <textarea class="form-control" rows="3"  v-model="gift.comment" />
              </div>

            </div>
            <div class="card-footer">
              <button class="btn btn-outline-success" @click="gift_update_clicked" >Update</button>
            </div>
          </div>
        </vmodal>
      </FocusTrap>

      <!--CME Window-->
      <FocusTrap>
        <vmodal name="cme_window" transition="nice-modal-fade" :delay="100" :resizable="true"  height="380" >
          <div class="card">
            <div class="card-title p-0" style="background-color: whitesmoke;">
              <h6 class="card-header">{{reporting.doctor.name}}  's <span class="font-weight-bolder text-danger-800">CME</span> </h6>
            </div>
            <div class="card-body">

              <div class="form-group row">
                <label>Title:</label>
                <input class="form-control" maxlength="50" v-model="cme.title" />
              </div>
              <div class="form-group row">
                <label>Comments:</label>
                <textarea class="form-control" rows="3"  v-model="cme.comment" />
              </div>

            </div>
            <div class="card-footer">
              <XPButton v-bind:title="UpdateSave" @handle_click_action="cme_update_clicked" ></XPButton>
<!--              <button class="btn btn-outline-success" >Update CME</button>-->
            </div>
          </div>
        </vmodal>
      </FocusTrap>

      <!--Pending CME's-->
      <FocusTrap>
        <vmodal name="pending_cme_window" transition="nice-modal-fade" :delay="100" :resizable="true"  height="380" >
          <div class="card">
            <div class="card-title p-0" style="background-color: whitesmoke;">
              <h6 class="card-header">{{reporting.doctor.name}}  's <span class="font-weight-bolder text-danger-800">Pending CME</span> </h6>
            </div>
            <div class="card-body">

              <div class="form-group row">
                <label>Pending Title:</label>
                <select class="form-control"   v-model="pending_cme.id" ></select>
              </div>
              <div class="form-group row">
                <label>Comments:</label>
                <textarea class="form-control" rows="3" v-model="pending_cme.comment"  />
              </div>
            </div>
            <div class="card-footer">
              <XPButton @handle_click_action="pending_cme_update_clicked" ></XPButton>
            </div>
          </div>
        </vmodal>
      </FocusTrap>

  </div>
  </FocusTrap>
</template>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
