<template>
  <div class="card" >
    <div class="card-header bg-teal text-white">
      <h4>Reasons for not meet</h4>
      <h4 class="font-weight-semibold">{{reporting.doctor.name}}</h4>
    </div>

    <div class="card-body">
      <div >
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="opReson" id="opRefused" value="Refused" v-model="reporting.not_met_type">
          <label class="form-check-label" for="opRefused">Refused</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="opReson" id="opOpbusy" value="OPBusy" v-model="reporting.not_met_type">
          <label class="form-check-label" for="opOpbusy">OP Busy</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="opReson" id="opOutofStation" value="OutofStation" v-model="reporting.not_met_type">
          <label class="form-check-label" for="opOutofStation">Out of Station</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="opReson" id="opOthers" value="Others" v-model="reporting.not_met_type">
          <label class="form-check-label" for="opOthers">Others</label>
        </div>
      </div>

      <div class="mb-3">
        <label for="txtRemarks" class="form-label">Remarks</label>
        <textarea class="form-control" id="txtRemarks" rows="3"  v-model="reporting.not_met_remarks" ></textarea>
      </div>

      <div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="cbLbl" v-model="reporting.lbl_reminder">
          <label class="form-check-label" for="cbLbl">
            LBL Reminder
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="cbSample" v-model="reporting.sample_reminder">
          <label class="form-check-label" for="cbSample">
            Sample Reminder
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="cbCmc" v-model="reporting.cmc_reminder">
          <label class="form-check-label" for="cbCmc">
            CMC Reminder
          </label>
        </div>
      </div>

    </div>
    <div class="card-footer text-center">
      <button type="button" class="btn btn-teal" @click="update_clicked">Update</button>
    </div>

  </div>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import {store} from '@/store/store.js'

export default {
  name: 'DoctorVisitNo',
  store,
  props:{
    reporting : {
      type :Object,
      default:() => JSON.parse('{"id":0,"work_date":"","doctor":{"id":0,"name":"Doctor Name","hospital":"","sex":"M","address":"","area_id":1,"qualification":"qualification","speciality":"","class":"","dob":"1900-01-01","dow":"","no_of_visits":0,"last_visit":"2000-01-01","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":1,"name":""},"categories":[]},"visit_time":"","visited":false,"not_met_type":"","not_met_remarks":"","lbl_reminder":false,"sample_reminder":false,"cmc_reminder":false,"promoted_products":[]}')
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    // alert(this.$props.reporting.doctor.name);
  },
  methods:{
    update_clicked(){
      const self = this;

      if(self.$props.reporting.not_met_type == null || self.$props.reporting.not_met_type == "") {
        alert('Invalid Meeting Type');
        return;
      }else if(self.$props.reporting.not_met_remarks == null || self.$props.reporting.not_met_remarks.trim().length == 0) {
        alert('Invalid Remarks');
        return;
      }

      self.$props.reporting.doctor_id = self.$props.reporting.doctor.id;
      self.$props.reporting.fieldpeople_id = self.$store.state.user.id;
      // self.$data.reporting.jointworker_id = resp.data.jointworker_id;
      self.$props.reporting.date =  self.$store.state.user.working_date;
      self.$props.reporting.work_date =  self.$store.state.user.working_date;
      self.$props.reporting.visited =  false;
      self.$props.reporting.visit_time =  "";

      this.$emit('doctor_novisit_updated');
    }
  }
}
</script>

<style scoped>

</style>
