<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title text-teal-800">{{product.name}} Promotion</h5>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-form-label font-weight-semibold col-md-2">Sample Qty</label>
          <input ref="txtQty" type="number" min="0" class="form-control col-md-2 text-center" placeholder="Qty" v-model="promotion.qty">

          <label class="col-form-label font-weight-semibold col-md-2">Comments</label>
          <input type="text" class="form-control col-md-6" autocomplete="off" :placeholder="comments_placeholer" v-model="promotion.comments">
        </div>

        <div class="form-group row">
          <label class="col-form-label font-weight-semibold col-md-2">Taste:</label>
          <input type="text" class="form-control col-md-6" autocomplete="off" placeholder="Taste Comments" v-model="promotion.taste">
          <select class="form-control col-md-4" v-model="promotion.taste_emoji">
            <option>👍</option>
            <option>👎</option>
            <option>😀</option>
            <option>😣</option>
            <option>🥱</option>
            <option>🥱</option>
          </select>
        </div>

        <div class="form-group row">
          <label class="col-form-label font-weight-semibold col-md-2">Smell:</label>
          <input type="text" class="form-control col-md-6" autocomplete="off" placeholder="Smell Comments" v-model="promotion.smell">
          <select class="form-control col-md-4" v-model="promotion.smell_emoji">
            <option>👍</option>
            <option>👎</option>
            <option>😀</option>
            <option>😣</option>
            <option>🥱</option>
            <option>🥱</option>
          </select>
        </div>

        <div class="form-group row">
          <label class="col-form-label font-weight-semibold col-md-2">Commitment</label>
          <input type="text" class="form-control col-md-10" autocomplete="off" placeholder="Commitment for this Product"  v-model="promotion.commitment">
        </div>


        <div class="form-group">
          <label class="col-form-label font-weight-semibold">Final Words:</label>
          <textarea rows="5" class="form-control" autocomplete="off" placeholder="Enter your message here" v-model="promotion.remarks"></textarea>
        </div>

        <div class="text-right">
          <button type="button" class="btn btn-outline-success-100" @click="perform_update_info">Update <i class="icon-database-edit2 ml-2"></i></button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportingProductInfo',
  props:{
    product:{
      type:Object,
      default: ()=>  JSON.parse('{"id":0,"name":""}')
    },
    mypromotion:{
      type:Object,
      default: ()=>  JSON.parse('{"id":0,"product":{"id":0,"name":""},"qty":0,"comments":"","taste":"","taste_emoji":"","smell":"","smell_emoji":"","commitment":"","remarks":""}')
    }
  },
  beforeMount () {
    const self = this;
    this.promotion = this.$props.mypromotion;

    this.$data.promotion.product.id = self.product.id;
    this.$data.promotion.product.name = self.product.name;

  },
  data(){
    return{
      comments_placeholer: `Comments about ${this.product.name}`,
      promotion : {"id":0,"product":{"id":0,"name":""},"qty":0,"comments":"","taste":"","taste_emoji":"","smell":"","smell_emoji":"","commitment":"","remarks":""},
    }
  },
  mounted () {
    const self = this;
    this.$refs.txtQty.focus();
  },
  methods:{
    perform_update_info(){
      const self = this;
      try {
        self.$emit('reporting_product_info_saved', self.$data.promotion);
      }catch (e) {
        alert(e);
      }
    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
